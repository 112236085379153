import * as React from 'react'

export const TechnicalSetting = (props: any) =>
    <svg width={props.svgwidth} height={props.svgheight} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.5 10.6665H13.5C11.9352 10.6665 10.6667 11.935 10.6667 13.4998V30.4998C10.6667 32.0646 11.9352 33.3332 13.5 33.3332H30.5C32.0648 33.3332 33.3334 32.0646 33.3334 30.4998V13.4998C33.3334 11.935 32.0648 10.6665 30.5 10.6665Z"
        stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path
        d="M24.8333 17.75H19.1667C18.3843 17.75 17.75 18.3843 17.75 19.1667V24.8333C17.75 25.6157 18.3843 26.25 19.1667 26.25H24.8333C25.6157 26.25 26.25 25.6157 26.25 24.8333V19.1667C26.25 18.3843 25.6157 17.75 24.8333 17.75Z"
        fill="#B8E5E1" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M26.25 7.8335V10.6668" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M26.25 33.3335V36.1668" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.83331 26.25H10.6666" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.83331 17.75H10.6666" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M33.3333 26.25H36.1666" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M33.3333 17.75H36.1666" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.75 7.8335V10.6668" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.75 33.3335V36.1668" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
